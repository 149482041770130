










































































import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import ConfirmationDialog from "@/shared/components/ConfirmationDialog.vue";
import { Dictionary, DictionaryType, MenuItemsSettings } from "@/shared/models";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";
import { SubscriptionEventModel } from "@/settings/models/subscription-events/SubscriptionEventModel";
import { ReportHeader } from "@/reports/models";

@Component({
  components: {
    ConfirmationDialog,
  },
})
export default class SubscriptionsView extends mixins(AppSectionAccessMixin) {
  search = "";
  isConfirmDialogVisible = false;
  deletedEvent: SubscriptionEventModel | null = null;

  get headers(): Array<ReportHeader> {
    return [
      {
        text: this.$lang(`appAdmin.subscriptionEvents.tableHeaders.token`),
        align: "start",
        value: "token",
        sortable: false,
      },
      {
        text: this.$lang(`appAdmin.subscriptionEvents.tableHeaders.type`),
        align: "start",
        value: "type",
        sortable: false,
      },
      {
        text: this.$lang(
          `appAdmin.subscriptionEvents.tableHeaders.appVersionFrom`
        ),
        align: "start",
        value: "appVersionFrom",
        sortable: false,
      },
      {
        text: this.$lang(
          `appAdmin.subscriptionEvents.tableHeaders.appVersionTo`
        ),
        align: "start",
        value: "appVersionTo",
        sortable: false,
      },
      ...(this.hasDeleteAccess
        ? [
            {
              text: this.$lang(
                `appAdmin.subscriptionEvents.tableHeaders.actions`
              ),
              align: "end",
              value: "actions",
              sortable: false,
            } as ReportHeader,
          ]
        : []),
    ];
  }

  get items(): Array<SubscriptionEventModel> {
    return this.$store.state.subscriptionEventsStore.subscriptionEvents;
  }

  get isDictionariesLoading(): boolean {
    return this.$store.state.dictionaryStore.loadingDictionary;
  }

  get eventNames(): Array<string> {
    return this.$store.state.dictionaryStore[
      DictionaryType.EVENT_NAMES
    ].values.map(({ value }: Dictionary) => value);
  }

  get isEventDeleting(): boolean {
    return this.$store.state.subscriptionEventsStore
      .isSubscriptionEventDeleting;
  }

  @Watch("applicationId", { immediate: true })
  fetchSubscriptionEvents() {
    this.$store.dispatch("fetchSubscriptionEvents", this.applicationId);
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("appAdmin.subscriptionEvents.title")
    );

    if (
      !this.$store.state.dictionaryStore[DictionaryType.EVENT_NAMES].values
        .length
    ) {
      this.$store.dispatch("loadDictionaries", {
        app: this.applicationId,
        dictionaryTypes: [DictionaryType.EVENT_NAMES],
      });
    }
  }

  handleClickByTableRow({ id }: SubscriptionEventModel) {
    if (!this.hasEditAccess) {
      return;
    }

    this.$router.push({
      name: MenuItemsSettings.SUBSCRIPTION_EVENTS_VIEW,
      params: {
        id: this.applicationId,
        subscriptionEventId: String(id),
      },
    });
  }

  showDeleteConfirmationDialog(item: SubscriptionEventModel) {
    this.deletedEvent = SubscriptionEventModel.ofSource(item);
    this.isConfirmDialogVisible = true;
  }

  createNewEvent() {
    this.$router.push({
      name: MenuItemsSettings.SUBSCRIPTION_EVENTS_NEW,
      params: {
        id: this.applicationId,
      },
    });
  }

  async deleteSubscriptionEvent() {
    await this.$store.dispatch("deleteSubscriptionEvent", {
      applicationId: this.applicationId,
      id: this.deletedEvent?.id,
    });
    this.isConfirmDialogVisible = false;
    this.deletedEvent = null;
  }
}
